import { Frame, Landmarks, Eyes, ErrorCorrection, ValidatedErrorCorrection } from "@lumen-developer/rni-webcam-js";

export interface Broker {
  trackingStart(gazeHandler: (gaze: BrokerGaze) => void): Promise<void>;
  trackingStop(): void;
  validate(valid: HTMLDivElement, timeout: number): Promise<OnValidationCompleteResult>;
}

export interface CameraBroker extends Broker {
  init(
    timeout: number,
    trackerDiv?: HTMLDivElement,
    dynConfig?: DynamicConfig,
    customEventListeners?: CustomEventListenersDef[]
  ): Promise<void>;
  calibrate(calib: HTMLDivElement, timeout: number): Promise<void>;
  saveModel(key: string, key2: string): void;
  loadModel(key: string, key2: string): Promise<void>;
}

export interface WebcamBroker extends CameraBroker {
    releaseVideoDiv(): void;
    setVideoDiv(trackerDiv: HTMLDivElement): void;
    getEyePos(eyePosHandler: (eyes: Eyes) => void): void;
    stopEyePos(): void;
    startCalculateFrameRate(): void;
    endCalculateFrameRate(): number;
    turnOnCamera(): void;
    turnOffCamera(): void;
}

export interface AudibleBroker extends Broker {
    calibrate(calib: HTMLDivElement, timeout: number): Promise<void>;
}

export type BrokerGaze = {
  success: boolean;
  x: number;
  y: number;
  original_x: number;
  original_y: number;
  raw_x: number;
  raw_y: number;
  blinks?: boolean;
  index: number;
  time_from_last: number;
  frame_rate: number;
};

export type OnNextLandmarksResult = {
  timestamp: number;
  frame: Frame;
  landmarks: Landmarks;
};

export type OnValidationCompleteResult = {
    timestamp: number;
    errorCorrection: ErrorCorrection;
    validatedErrorCorrection: ValidatedErrorCorrection;
}

export type DynamicConfig = {
  isMobile?: boolean;
  configV2?: boolean;
  testEyetracking?: boolean;
  videoSrc?: string;
  silhouette?: boolean;
  useWebWorkers?: boolean;
  useGroundTruth?: boolean;
  resolveEvent?: INIT_RESOLVE_EVENT;
};

export type CustomEventListenersDef = {
  eventName: string;
  cb: Function;
};

export enum INIT_RESOLVE_EVENT {
  FIRST_FRAME,
  PROMISE_ALL,
  GAZE_DETECTOR_INIT
}