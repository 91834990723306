import React, { useRef, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";

import { SessionRoute, SessionSectionContext } from "@/types/session";

const SessionLighting = () => {
  const {
    broker: { clearQualityTimeout, getEyeIlluminationQuality },
    setStatus,
    status: { lighting },
  } = useOutletContext<SessionSectionContext>();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cont = () => {
    clearQualityTimeout();
    navigate(SessionRoute.POSITION);
  };

  const normalize = (value: number) => {
    const mx = Math.log(value - 0) / Math.log(100 - 0);
    const preshiftNormalized = mx * (100 - 70);
    const shiftedNormalized = preshiftNormalized + 70;

    return Math.round(shiftedNormalized);
  };

  useEffect(() => {
    getEyeIlluminationQuality((res) => {
      setStatus((value) => ({ ...value, lighting: normalize(res * 100) }));
    });
  }, [getEyeIlluminationQuality, setStatus]);

  return (
    <>
      <p className="font-semibold mb-10">Step 1: Lighting</p>
      <p className="mb-10">
        So we can see your eyes, being in a well lit room is best.
      </p>
      <p className="mb-10">
        To continue, a lighting score of 5 or more is required.
      </p>
      <Button
        color="secondary"
        onClick={cont}
        disabled={lighting < 80}
        ref={buttonRef}
      >
        Continue
      </Button>
    </>
  );
};

export default SessionLighting;
