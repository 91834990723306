import React from "react";

const Tasks = () => {
  return (
    <section className="mb-10">
      <p className="font-semibold mb-6">Tasks</p>
    </section>
  );
};

export default Tasks;
