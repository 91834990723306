import React, { useEffect } from "react";
import { Button, Link } from "@nextui-org/react";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppDispatch } from "@/hooks/store";
import { logIn } from "@/store/reducers/auth";
import { loginRequest } from "@/api/index";
import { useAlertContext } from "@/contexts/alertContext";
import Input from "../input/input";

interface ILoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useAppDispatch();
  const { addAlert } = useAlertContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<ILoginForm>();

  const sendLogIn: SubmitHandler<ILoginForm> = async ({ email, password }) => {
    try {
      const res = await loginRequest(email, password);
      dispatch(logIn(res));
    } catch (e) {
      // TODO get safe error message?
      addAlert({ type: "error", message: "Login failed", timeout: 0 });
      throw new Error(e);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(sendLogIn)}>
      <h1 className="text-2xl mb-5">The Focus Project</h1>
      <Input
        label="Email Address"
        type="text"
        autoComplete="email"
        register={register("email", {
          required: "Please enter your email address",
          pattern: {
            value: /^\S+@\S+$/i,
            message: "Please enter a valid email address",
          },
        })}
        errorMessage={errors?.email?.message}
      />
      <Input
        label="Password"
        type="password"
        register={register("password", {
          required: "Please enter your password",
        })}
        errorMessage={errors?.password?.message}
      />
      <div className="flex items-center justify-content gap-9">
        <Button color="secondary" type="submit">
          Log In
        </Button>
        <Link href="/reset-password">Forgot Password?</Link>
      </div>
    </form>
  );
};

export default Login;
