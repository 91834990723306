import React from "react";

const NotFound = () => {
  return (
    <p className="mt-20 text-center text-3xl text-secondary-600">
      This page could not be found
    </p>
  );
};

export default NotFound;
