import React, { useRef, useState } from "react";
import {
  Outlet,
  useLocation,
  Navigate,
  matchRoutes,
  useNavigate,
} from "react-router-dom";
import { VideoCameraSlashIcon } from "@heroicons/react/24/outline";
import { LRExtensionBroker } from "@lumen-developer/lumen-common-js/esm/brokers";

import {
  ErrorDetail,
  SessionCalibrationContext,
  SessionExternalContext,
  SessionRoute,
  SessionSectionContext,
  SessionStartContext,
  SessionValidationContext,
} from "@/types/session";
import { useAppDispatch, useAppSelector } from "@/hooks/store";
import ErrorFormatter, {
  ErrorFormat,
  GENERIC_SAFE_FMT,
} from "@/utils/errorFormat";
import ScoresTable, { ISessionStatus } from "./sections/scoreTable";
import SessionError from "./sections/error";

// TODO: ERRORS

const Session = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const panelist = useAppSelector((state) => state.auth.panelist);
  const panelistId = useAppSelector((state) => state.auth.panelistId);
  const accessCode = useAppSelector((state) => state.auth.accessCode);
  const maxAcc = useAppSelector((state) => state.auth.maxAcc);
  const calibrationTime = useAppSelector(
    (state) => state.session.calibrationTime,
  );
  const tmpSessionId = useAppSelector((state) => state.session.tmpSessionId);
  const dispatch = useAppDispatch();
  const [hasError, setHasError] = useState(false);
  const [status, setStatus] = useState<ISessionStatus>({
    position: 0,
    validation: 0,
    lighting: 0,
  });
  const [errorDetail, setErrorDetail] = useState<ErrorDetail>({
    fmt: GENERIC_SAFE_FMT,
    action: () => navigate("/"),
  });
  const [broker] = useState<LRExtensionBroker>(new LRExtensionBroker());

  const trackerRef = useRef<HTMLDivElement>(null);
  const calibrationRef = useRef<HTMLDivElement>(null);
  const validationRef = useRef<HTMLDivElement>(null);

  const routeObjectFactory = (
    arr: SessionRoute[],
  ): { path: SessionRoute }[] => {
    return arr.map((r) => {
      return {
        path: r,
      };
    });
  };
  const directAccessMatch = matchRoutes(
    routeObjectFactory([SessionRoute.START, SessionRoute.RETURN]),
    location,
  );
  const showScoreTableMatch = matchRoutes(
    routeObjectFactory([
      SessionRoute.LIGHTING,
      SessionRoute.POSITION,
      SessionRoute.CALIBRATION,
      SessionRoute.VALIDATION,
      SessionRoute.EXTERNAL,
    ]),
    location,
  );
  const showCameraTrackerMatch = matchRoutes(
    routeObjectFactory([
      SessionRoute.START,
      SessionRoute.LIGHTING,
      SessionRoute.POSITION,
      SessionRoute.CALIBRATION,
      SessionRoute.VALIDATION,
      SessionRoute.EXTERNAL,
    ]),
    location,
  );

  const showPositioningScore = matchRoutes(
    routeObjectFactory([
      SessionRoute.POSITION,
      SessionRoute.CALIBRATION,
      SessionRoute.VALIDATION,
      SessionRoute.EXTERNAL,
    ]),
    location,
  );

  const showValidationScore = matchRoutes(
    routeObjectFactory([SessionRoute.EXTERNAL]),
    location,
  );

  const errorHandle = (e: ErrorDetail | ErrorFormat | any) => {
    setHasError(true);
    if (e.fmt && e.action && e.route) {
      setErrorDetail(e);
      navigate(e.route);
    } else if (e.logLevel) {
      const detail = {
        fmt: e,
        action: () => window.location.reload(),
      };
      setErrorDetail(detail);
    } else {
      const detail = {
        fmt: ErrorFormatter.formatError(e),
        action: () => window.location.reload(),
      };
      setErrorDetail(detail);
    }
  };

  // redirect to start if broker is uninitialised on sections that expect it
  if (!directAccessMatch) {
    if (!broker.state.initialised)
      return <Navigate to={SessionRoute.START} replace />;
  }

  const sessionContext = ():
    | SessionSectionContext
    | SessionStartContext
    | SessionCalibrationContext
    | SessionValidationContext
    | SessionExternalContext => {
    switch (location.pathname) {
      case SessionRoute.START:
        return {
          broker,
          dispatch,
          panelist,
          panelistId,
          accessCode,
          trackerRef,
          errorHandle,
        };
      case SessionRoute.LIGHTING:
        return {
          broker,
          dispatch,
          setStatus,
          status,
          errorHandle,
        };
      case SessionRoute.POSITION:
        return {
          broker,
          dispatch,
          setStatus,
          status,
          errorHandle,
        };
      case SessionRoute.CALIBRATION:
        return {
          dispatch,
          broker,
          calibrationRef,
          trackerRef,
          errorHandle,
        };
      case SessionRoute.VALIDATION:
        return {
          broker,
          dispatch,
          validationSessionDetails: {
            panelist,
            panelistId,
            tmpSessionId,
            calibrationTime,
            accessCode,
          },
          validationRef,
          trackerRef,
          errorHandle,
          setStatus,
        };
      case SessionRoute.EXTERNAL:
        return {
          broker,
          accessCode,
          panelist,
          panelistId,
          tmpSessionId,
          dispatch,
          errorHandle,
        };
      case SessionRoute.RETURN:
        return {
          broker,
          dispatch,
          validationSessionDetails: {
            panelist,
            panelistId,
            tmpSessionId,
            calibrationTime,
            accessCode,
          },
          validationRef,
          trackerRef,
          errorHandle,
        };
      case SessionRoute.COMPLETE:
      default:
        return {
          broker,
          dispatch,
          errorHandle,
        };
    }
  };

  return (
    <>
      <div
        className={`h-full w-full fixed bg-white top-0 left-0 z-50 ${
          hasError ? "hidden" : "visible"
        }`}
        style={{ display: "none" }}
        ref={calibrationRef}
      />
      <div
        className={`h-full w-full fixed bg-white top-0 left-0 z-50 ${
          hasError ? "hidden" : "visible"
        }`}
        style={{ display: "none" }}
        ref={validationRef}
      />
      <div className="w-full lg:w-1/2 flex-start">
        {hasError ? (
          <SessionError errorDetail={errorDetail} />
        ) : (
          <Outlet context={sessionContext()} />
        )}
      </div>
      <div className="w-full lg:w-1/2">
        {trackerRef?.current ? (
          <div
            ref={trackerRef}
            className={`w-full min-h-80 lg:h-1/3 border-2 border-gray-400 rounded ${
              showCameraTrackerMatch ? "visible" : "hidden"
            }`}
          />
        ) : (
          <div
            ref={trackerRef}
            className="w-full min-h-80 lg:h-1/3 border-2 border-gray-400 rounded flex items-center"
          >
            <VideoCameraSlashIcon className="text-default-700 w-full stroke-1 h-48" />
          </div>
        )}
        <div className={`${showScoreTableMatch ? "visible" : "hidden"}`}>
          <p className="mt-6 mb-2">Your scores:</p>
          <ScoresTable
            {...{
              status,
              maxAcc,
              showPositioningScore: !!showPositioningScore?.length,
              showValidationScore: !!showValidationScore?.length,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Session;
