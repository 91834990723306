import React, { useEffect, useState } from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Button, Spinner } from "@nextui-org/react";
import { INIT_RESOLVE_EVENT } from "@lumen-developer/lumen-common-js/esm/brokers";

import { updateDetails } from "@/store/reducers/auth";
import { useAppSelector } from "@/hooks/store";
import {
  ErrorDetail,
  SessionRoute,
  SessionStartContext,
} from "@/types/session";
import { useAlertContext } from "@/contexts/alertContext";
import { getPanelistDetails, getSessionRequest } from "../../../api";
import { setTmpSessionId } from "../../../store/reducers/session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";
import extensionSendMessage from "../../../utils/extensionCom";

const SessionStart = () => {
  const {
    broker,
    dispatch,
    panelist,
    panelistId,
    accessCode,
    trackerRef,
    errorHandle,
  } = useOutletContext<SessionStartContext>();
  const navigate = useNavigate();
  const { addAlert } = useAlertContext();

  const [canStart, setCanStart] = useState(false);

  const maxAcc = useAppSelector((state) => state.auth.maxAcc);
  const maxPrec = useAppSelector((state) => state.auth.maxPrec);
  const brokerVersionConfig = useAppSelector(
    (state) => state.auth.brokerVersionConfig,
  );
  const viewabilityVersionConfig = useAppSelector(
    (state) => state.auth.viewabilityVersionConfig,
  );

  useEffect(() => {
    const innerAsync = async () => {
      try {
        const details = await getPanelistDetails(accessCode, panelistId);
        dispatch(updateDetails(details));
        setCanStart(true);
      } catch (e) {
        errorHandle(e);
      }
    };
    innerAsync();
  }, [accessCode, errorHandle, panelistId, dispatch]);

  const initBroker = async () => {
    const div = trackerRef.current;
    if (!div) {
      const message = ErrorFormatter.getError(
        ErrorClass.TRACKER_DIV,
      ).safeMessage;
      addAlert({ type: "error", message, timeout: 5 });
      throw new Error(message);
    }
    await broker.init(30000, div, {
      resolveEvent: INIT_RESOLVE_EVENT.GAZE_DETECTOR_INIT,
    });
  };
  const cont = async () => {
    setCanStart(false);
    try {
      const m: PanelMessages.ExternalMessage = {
        type: PanelMessages.ExternalMessageType.CheckPermissions,
      };
      const perms: boolean = await extensionSendMessage(m);
      if (!perms) {
        const message = ErrorFormatter.getError(
          ErrorClass.CAMERA_PERMISSIONS,
        ).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
      }
      const res = await getSessionRequest(
        panelist,
        maxAcc,
        maxPrec,
        brokerVersionConfig,
        viewabilityVersionConfig,
      );
      dispatch(setTmpSessionId(res.tmpSessionId));
      // TODO: HANDLE GET SESSION ID ERROR
      if (!broker.state.initialised) {
        await initBroker();
      }
      navigate(SessionRoute.LIGHTING);
    } catch (e) {
      if (e === ErrorClass.EXTENSION_COMMS_FAIL) {
        window.location.href = "/install";
      } else if (e === ErrorClass.CAMERA_PERMISSIONS) {
        const detail: ErrorDetail = {
          fmt: ErrorFormatter.getError(e),
          action: () => window.location.reload(),
        };
        errorHandle(detail);
      } else {
        errorHandle(e);
      }
    }
    setCanStart(true);
  };
  return (
    <>
      <div className="mb-10">
        {canStart ? (
          <Button color="secondary" disabled={!canStart} onClick={cont}>
            Start a 10 minute Research session
          </Button>
        ) : (
          <Spinner />
        )}
      </div>
      <p className="mb-10">Note: We do not record pictures or video of you</p>
    </>
  );
};

export default SessionStart;
