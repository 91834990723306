import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Link, Button, Checkbox } from "@nextui-org/react";
import { useForm, SubmitHandler } from "react-hook-form";

import Input from "@/components/input/input";

interface ISignUpForm {
  email: string;
  terms: boolean;
}

interface SignUpFormProps {
  setConfirmationSent: Dispatch<SetStateAction<boolean>>;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ setConfirmationSent }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<ISignUpForm>();

  const onSubmit: SubmitHandler<ISignUpForm> = ({ email }) => {
    try {
      // TODO: Create user account and send sign up email
      // show resend email page
      console.log(email);
      setConfirmationSent(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="text-2xl mb-5">The Focus Project</p>
      <Input
        label="Email Address"
        type="email"
        placeholder="Username"
        register={register("email", {
          required: "Please enter your email address",
          pattern: {
            value: /^\S+@\S+$/i,
            message: "Please enter a valid email address",
          },
        })}
        autoComplete="email"
        errorMessage={errors?.email?.message}
      />
      <Checkbox
        {...register("terms", {
          required: "You must accept the terms and conditions",
        })}
      >
        <div className="inline-flex gap-1">
          <p className="block text-sm">I have read and agree to the</p>
          <Link href="/terms-and-conditions" size="sm">
            terms and conditions
          </Link>
        </div>
      </Checkbox>
      <div className="text-danger text-xs mb-4">{errors?.terms?.message}</div>
      <div className="flex items-center justify-content gap-9">
        <Button type="submit" color="secondary">
          Sign Up
        </Button>
        <Link href="/">Already have an account?</Link>
      </div>
    </form>
  );
};

export default SignUpForm;
