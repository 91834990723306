/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Accordion, AccordionItem } from "@nextui-org/react";

import questionGroups from "src/data/faqs";

const FAQs = () => {
  return (
    <section className="mb-10">
      <h1 className="font-semibold mb-5 text-2xl">
        Frequently Asked Questions
      </h1>
      <Accordion variant="bordered" className="font-semibold">
        {questionGroups.map(({ category, data }) => (
          <AccordionItem
            key={category}
            aria-label={`Accordion ${category}`}
            title={category}
          >
            <div className="font-normal">
              <Accordion variant="light">
                {data.map(({ title, content }, index) => (
                  <AccordionItem
                    key={title}
                    aria-label={`Accordion 2 ${index}`}
                    title={title}
                  >
                    <div className="flex flex-col gap-4">{content}</div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};

export default FAQs;
