import packageInfo from "../../package.json";

const rollbarConfig = {
  accessToken: "a99b3d0afb81456bbcd1577b8877cd89",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: { version: `${packageInfo.version}-web` },
    },
  },
  environment: process.env.ENVIRONMENT ? process.env.ENVIRONMENT : "release",
};

export default rollbarConfig;
