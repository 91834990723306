import React, { useEffect } from "react";
import { useRollbar } from "@rollbar/react";
import { Button } from "@nextui-org/react";

import { ErrorDetail } from "@/types/session";
import { LOGLEVEL } from "@/utils/errorFormat";

interface SessionErrorProps {
  errorDetail: ErrorDetail;
}

const SessionError: React.FC<SessionErrorProps> = ({ errorDetail }) => {
  const rollbar = useRollbar();

  useEffect(() => {
    switch (errorDetail.fmt.logLevel) {
      case LOGLEVEL.ERROR:
        rollbar.error(errorDetail.fmt.internalMessage);
        break;
      case LOGLEVEL.WARN:
        rollbar.warn(errorDetail.fmt.internalMessage);
        break;
      case LOGLEVEL.INFO:
        rollbar.info(errorDetail.fmt.internalMessage);
        break;
      case LOGLEVEL.SILENT:
      default:
        break;
    }
  }, [errorDetail, rollbar]);

  return (
    <>
      <p className="mb-10 text-red-500">{errorDetail.fmt.safeMessage}</p>
      <Button color="secondary" onClick={errorDetail.action}>
        Try again
      </Button>
    </>
  );
};

export default SessionError;
