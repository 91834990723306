/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link, Image } from "@nextui-org/react";

import { BulletList } from "@/components/bulletList/bulletList";
import FPIcon from "../assets/focus_project.svg";

export interface IContentData {
  title: string;
  content: JSX.Element;
}

export interface IAccordionItem {
  category: string;
  data: IContentData[];
}

const extensionIcon = (
  <div className="inline-flex items-center border rounded-full h-[50px] w-[50px] py-4 px-1 mx-1">
    <Image width={40} alt="Focus project icon" src={FPIcon} radius="none" />
  </div>
);

const generalQuestions: IContentData[] = [
  {
    title: "How long will the research go on for?",
    content: (
      <p>
        The research is currently ongoing with no specified end-data. You will
        be notified by email if the project is terminated.
      </p>
    ),
  },
  {
    title: "How often do I need to participate?",
    content: (
      <p>
        We would like you to participate in research a few times a week but its
        totally up to you. Over time we may introduce more ways to earn points.
      </p>
    ),
  },
  {
    title: "How do I unsubscribe from the research?",
    content: (
      <p>
        You can unsubscribe from the research by sending a{" "}
        <Link href="/data-deletion-request">data deletion request</Link>, and we
        will explain how to return your eye tracking device and remove the
        software. You will be removed from the database immediately but may
        receive emails for the next 28 days.
      </p>
    ),
  },
  {
    title: "Is everyone suitable for eye tracking?",
    content: (
      <p>
        For medical reasons, there may be a small percentage of the population
        that may not be suitable for the eye tracking device. Please refer to
        the Terms and Conditions for further information.
      </p>
    ),
  },
  {
    title: "What is expected of me when participating?",
    content: (
      <p>
        In order to take part in the research you will need to start browsing
        sessions on the web or apps that we want to understand better and turn
        on your webcam so we can see what you engage with on these sites and
        platforms.
      </p>
    ),
  },
  {
    title: "What is the extension?",
    content: (
      <>
        <p>
          The Focus Project extension is installed in Chrome, once you have
          finished the installation process of the project. The following
          interface icon (extension) will automatically appear next to your
          internet address bar in Chrome:
        </p>
        {extensionIcon}
        <p>
          Please remember that this won't work on mobile versions of the Chrome
          browser.
        </p>
      </>
    ),
  },
  {
    title: "What is the research for?",
    content: (
      <p>
        The objective of the research is to understand how people interact with
        online media.
      </p>
    ),
  },
  {
    title: "Which browser do you need to use when you are browsing?",
    content: (
      <p>
        You need to use the Chrome web browser to participate in the research.
        Web browsing carried out in any other web browser, even if your eye
        tracking device is turned on, will not count as participation.
      </p>
    ),
  },
];

// TODO: ad link to amazon rewards
const rewardQuestions = [
  {
    title: "How many points have I collected?",
    content: (
      <p>
        You can see your points in the <Link href="/rewards">rewards</Link>{" "}
        section.
      </p>
    ),
  },
  {
    title: "What rewards do i receive for participating?",
    content: (
      <p>
        You can cash in your points to receive Amazon vouchers the rewards{" "}
        <Link href="/rewards">rewards</Link> section.
      </p>
    ),
  },
];

const securityQuestions = [
  {
    title: "How is my privacy protected?",
    content: (
      <>
        <p>
          Your privacy is protected in several ways. Firstly, eye tracking data
          is collected from websites that are listed on an approved 'white
          list'.
        </p>
        <p>
          To protect your privacy and sensitive information this white list
          excludes certain sites such as banking websites.
        </p>
        <p>
          Data that is typed into an input box on a website is also not
          collected.
        </p>
        <p>
          There is also no tracking of web browsing undertaken in any web
          browser other than Chrome.
        </p>
      </>
    ),
  },
  {
    title: "How secure is my data?",
    content: (
      <>
        <p>
          The data collected by Lumen is encrypted using SSL technology. This is
          the most commonly used high standard of encryption technology and is
          used by banks to secure banking transactions and other highly
          sensitive communications. It is also used by Amazon, PayPal, eBay, and
          other websites requiring bank-level security systems. Scientists
          estimate that breaking 256 bit encryption using current technology
          would require substantially more time than the age of the universe.
        </p>
        <p>
          Collected data is stored on secure web servers managed by Amazon Web
          Services (AWS). AWS is one of the most commonly used safe and
          dependable cloud computing services platforms and is used by major
          cloud-based commercial services including Netflix and Expedia.
        </p>
      </>
    ),
  },
  {
    title: "What data are you collecting?",
    content: (
      <p>
        When the eye tracking is turned on we will be collecting information
        about the 'white-listed' web pages you visited in the Chrome web
        browser, what the pages looked like and where your eyes were focusing as
        you browsed. We will never collect any information about any web
        browsing undertaken in any web browser other than Chrome.
      </p>
    ),
  },
  {
    title: "What information are you collecting?",
    content: (
      <>
        <p>
          The objective of this research is to understand how people view and
          interact with online media. The information will be analysed in
          aggregate, and will not be attributed to you individually.
        </p>
        <p>
          Please take a look at the{" "}
          <Link href="/terms-and-privacy">
            Terms and Conditions and Privacy Policy
          </Link>{" "}
          for more information about what we are collecting and how it will be
          used.
        </p>
      </>
    ),
  },
  {
    title:
      "Will the software track the activities of other members of my household? ",
    content: (
      <>
        <p>
          The software will not differentiate between different users of a
          single computer, you should turn off the eye tracking device when
          other members of your household are using your computer.
        </p>
        <p>
          Please see the{" "}
          <Link href="/terms-and-privacy">Terms and Conditions</Link> for
          further information.
        </p>
      </>
    ),
  },
  {
    title:
      "Through my camera will you be watching me or able to look into my home?",
    content: (
      <p>
        No we do not keep any of the photographic or video feed data from your
        camera feed. We use it to gather a set of coordinates which represents
        where a user was looking on the screen at a given point an time during a
        session.
      </p>
    ),
  },
];

const technicalQuestions = [
  {
    title:
      "I don't see the focus project extension button next to my browser address bar, what should I do?",
    content: (
      <>
        <p>Please make sure that you have installed the software properly.</p>
        <p>
          First, in the Chrome web browser, click on the menu button (the three
          horizontal lines next to your internet address bar) then click
          'Settings' and 'Extensions' on the left hand side. Check that 'The
          Focus Project' is present in the list of extensions. If so, make sure
          the 'Enable' box is ticked and, if the option is available, click on
          'Show button'. The extension button should now be visible next to the
          address bar in Chrome.
        </p>
        <p>
          If 'The Focus Project' is not listed in the list of Chrome extensions
          this may be because the extension has been uninstalled. To re-install
          the extension, open Chrome then click{" "}
          <Link
            href={`https://chromewebstore.google.com/detail/the-focus-project/${
              process.env.EXTENSION_ID
            }`}
            isExternal
          >
            here
          </Link>{" "}
          and follow the instructions under "Enable the Focus Project extension
          in Chrome".
        </p>
      </>
    ),
  },
  {
    title: "I can't complete the calibration, what should I do?",
    content: (
      <>
        <p>
          Some things can affect the operation of the eye tracking through your
          camera and prevent successful calibration. If you are finding it
          difficult to calibrate the device there are several things you can
          try:
        </p>
        <BulletList>
          <li>
            Direct sunlight or the presence of other equipment that emits light
            can interfere with the device.
          </li>
          <li>
            Try operating the device in a darkened room or without any direct
            light shining behind you to see if this improves the calibration.
          </li>
          <li>
            If you are wearing glasses, please ensure that they are clean. If
            your glasses have multiple scratches or marks on them this could
            deteriorate the quality of the eye tracking so cleaning your glasses
            or trying an alternative pair may help.{" "}
          </li>
          <li>
            Try keeping your eyes wide open during the calibration process.
          </li>
          <li>
            Try keeping your head still during the calibration and try to sit
            looking at your computer in a straight position.{" "}
          </li>
          <li>
            Make sure you are not sitting too close to, or too far away from,
            the screen. Your camera can calibrate for a distance of about 60-80
            cm.
          </li>
        </BulletList>
      </>
    ),
  },
];

const questionGroups: IAccordionItem[] = [
  {
    category: "General",
    data: generalQuestions,
  },
  {
    category: "Reward",
    data: rewardQuestions,
  },
  {
    category: "Security",
    data: securityQuestions,
  },
  {
    category: "Technical",
    data: technicalQuestions,
  },
];

export default questionGroups;
