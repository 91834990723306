import React, { useState } from "react";

import EmailConfirmation from "@/components/signUp/emailConfirmation";
import SignUpForm from "@/components/signUp/signUpForm";

const SignUp = () => {
  const [confirmationSent, setConfirmationSent] = useState(false);

  if (confirmationSent)
    return <EmailConfirmation setConfirmationSent={setConfirmationSent} />;

  return <SignUpForm setConfirmationSent={setConfirmationSent} />;
};

export default SignUp;
