import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@nextui-org/react";

const Rewards = () => {
  const savedPoints = 1000;
  const lifetimePoints = 23900;
  const pages = 34;
  const seconds = 30;
  const navigate = useNavigate();

  const convertNumber = (amount: number, decimalPlaces: number = 0) => {
    return amount.toLocaleString("en", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  };

  return (
    <div>
      <section className="mb-10">
        <p className="font-semibold mb-6">
          Current saved reward pot this month
        </p>
        <p className="mb-6">
          You have {convertNumber(savedPoints)} points saved which is the
          equivalent of £{convertNumber(savedPoints / 1000, 2)}
        </p>
        {/* TODO: Add link to cash in reward */}
        <Button color="secondary" onClick={() => null}>
          Cash in reward
        </Button>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-6">Facts about you latest session</p>
        <p className="mb-6">
          You browsed {convertNumber(pages)} pages for an average time of{" "}
          {convertNumber(seconds)} seconds
        </p>
        <Button
          type="submit"
          color="secondary"
          onClick={() =>
            setTimeout(() => {
              navigate("/");
            }, 200)
          }
        >
          Start another session
        </Button>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-6">Lifetime rewards</p>
        <p className="mb-6">
          In total you have earned {convertNumber(lifetimePoints)} points which
          is the equivalent of £{convertNumber(lifetimePoints / 1000, 2)}
        </p>
      </section>
    </div>
  );
};

export default Rewards;
