/* eslint-disable react/no-unescaped-entities */
import React from "react";

import privacy from "@/data/privacy";
import terms from "@/data/terms";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { IAccordionItem } from "@/data/faqs";

const TermsAndPrivacy = () => {
  const accordionItems: IAccordionItem[] = [
    {
      category: "Terms and Conditions",
      data: terms,
    },
    {
      category: "Privacy Policy",
      data: privacy,
    },
  ];

  return (
    <>
      <h1 className="font-semibold mb-10 text-2xl">
        Focus Project Research - Terms and Privacy
      </h1>
      <Accordion variant="bordered">
        {accordionItems.map(({ category, data }) => (
          <AccordionItem title={category} key={category} aria-label={category}>
            {data.map(({ title, content }) => {
              return (
                <div key={title} className="mb-8">
                  <h3 className="font-semibold mb-4">{title}</h3>
                  <div className="flex flex-col gap-4">{content}</div>
                </div>
              );
            })}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default TermsAndPrivacy;
