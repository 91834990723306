import React, { useState } from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";

import { SessionExternalContext } from "@/types/session";
import { useAlertContext } from "@/contexts/alertContext";
import { startWildRequest } from "../../../api";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";
import extensionSendMessage from "../../../utils/extensionCom";

const SessionExternal = () => {
  const {
    broker,
    panelist,
    tmpSessionId,
    panelistId,
    accessCode,
    errorHandle,
  } = useOutletContext<SessionExternalContext>();
  const { addAlert } = useAlertContext();
  const [canClick, setCanClick] = useState(true);

  // const currentDate = new Date();
  // const sessionEndDate = new Date(
  //   currentDate.setMinutes(currentDate.getMinutes() + 10),
  // );

  const start = async () => {
    setCanClick(false)
    try {
      broker.saveModel("model", "errorCorrection");
      if (broker.trackersController) {
        await broker.turnOffCamera();
      }
      const model = localStorage.getItem("model");
      const errorCorrection = localStorage.getItem("errorCorrection");
      // const extensionId = process.env.EXTENSION_ID;

      if (model && errorCorrection) {
        console.log("sending model to extension...");
        const m: PanelMessages.InitSession = {
          type: PanelMessages.ExternalMessageType.InitSession,
          data: {
            broker: {
              model,
              errorCorrection,
            },
            panelist,
            panelistId,
            tmpSessionId,
            sessionLength: 600000, // TODO: SHOULD BE FROM STATE,
          },
        };
        const canStart = await extensionSendMessage(m);
        if (canStart) {
          await startWildRequest(accessCode, tmpSessionId, panelistId);
          // TODO: BETTER HANDLE SESSION START REDIRECT
          window.location.href = "https://google.com/";
        } else {
          const message = ErrorFormatter.getError(
            ErrorClass.EXT_INIT_FAIL,
          ).safeMessage;
          addAlert({ type: "error", message, timeout: 5 });
          throw new Error(message);
        }
      } else {
        const message = ErrorFormatter.getError(
          ErrorClass.MISSING_MODEL,
        ).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }
    } catch (e) {
      errorHandle(e);
    }
  };

  // const debug = async () => {
  //   try {
  //     broker.saveModel("model", "errorCorrection");
  //     if (!!broker.trackersController) {
  //       await broker.turnOffCamera();
  //     }
  //     const model = localStorage.getItem("model");
  //     const errorCorrection = localStorage.getItem("errorCorrection");
  //     const extensionId = process.env.EXTENSION_ID;

  //     if (model && errorCorrection) {
  //       console.log("sending model to extension...");
  //       let m: PanelMessages.InitSession = {
  //         type: PanelMessages.ExternalMessageType.InitSession,
  //         data: {
  //           broker: {
  //             model,
  //             errorCorrection,
  //           },
  //           panelist: panelist,
  //           panelistId: panelistId,
  //           tmpSessionId: tmpSessionId,
  //           // sessionLength: 10000, // 10 seconds
  //           sessionLength: 200000, // TODO: SHOULD BE FROM STATE,
  //         },
  //       };
  //       const canStart = await extensionSendMessage(m);
  //       console.log(canStart)
  //       if (canStart) {
  //         // TODO: BETTER HANDLE SESSION START REDIRECT
  //         // window.location.href = "https://google.com/";
  //       } else {
  //         throw ErrorFormatter.getError(ErrorClass.EXT_INIT_FAIL)
  //       }
  //     } else {
  //       throw ErrorFormatter.getError(ErrorClass.MISSING_MODEL)
  //     }
  //   } catch (e) {
  //     errorHandle(e);
  //   }
  // };

  return (
    <>
      <p className="font-semibold text-lg mb-10">
        How to earn points in your session?
      </p>
      <ol className="mb-10 list-decimal list-inside">
        <li>You will be redirected to a random website</li>
        <li>
          For the duration of the session browse the internet as you usually
          would
        </li>
        <li>
          After 10 minutes you will be automatically redirected in a new tab to
          a validation screen
        </li>
        <li>Complete a successful validation in order to secure your points</li>
      </ol>
      {/* TODO: BETTER DATE FORMATTING */}
      <p className="mb-10">Your session will last 10 minutes.</p>
      <Button color="secondary" disabled={!canClick} onClick={start}>
        Start Session
      </Button>
      {/* {process.env.NODE_ENV === "local" ? <button
        onClick={debug}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        DEBUG SEND TO EXTENSION
      </button> : null} */}
    </>
  );
};

export default SessionExternal;
