import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";

import { Alert, AlertsWrapper, IAlert } from "@/components/alert/alert";

interface IAlertWithId extends IAlert {
  id?: string;
}

export interface IAlertContextProps {
  addAlert: (alert: IAlert) => void;
}

export const alertContext = createContext({} as IAlertContextProps);

export function useAlertContext(): IAlertContextProps {
  return useContext(alertContext);
}

const AlertContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alerts, setAlerts] = useState<IAlertWithId[]>([]);

  const closeAlert = (id: string) => {
    setAlerts((value) => value.filter((alert) => alert.id !== id));
  };

  const memoizedValue = useMemo(() => {
    const addAlert = (alert: IAlert) => {
      const id = uuidv4();
      setAlerts((value) => [{ ...alert, id }, ...value]);
    };
    return { addAlert };
  }, [setAlerts]);

  return (
    <alertContext.Provider value={memoizedValue}>
      <alertContext.Consumer>
        {() => {
          return (
            <>
              <AlertsWrapper>
                {alerts.map((alert) => (
                  <li key={alert.id} className="mt-2">
                    <Alert
                      {...alert}
                      handleClose={() => {
                        closeAlert(alert.id);
                      }}
                    />
                  </li>
                ))}
              </AlertsWrapper>
              {children}
            </>
          );
        }}
      </alertContext.Consumer>
    </alertContext.Provider>
  );
};

export default AlertContextProvider;
