import { Button, Select, SelectItem } from "@nextui-org/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Input from "@/components/input/input";
import { useAppSelector } from "@/hooks/store";
import { deleteDataRequest, getDataDeletionReasonList } from "../../api";

interface IDataDeletionForm {
  reason: string;
  email: string;
}

interface DataDeletionFormProps {
  setDeleteSubmitted: Dispatch<SetStateAction<boolean>>;
}

const DataDeletionForm: React.FC<DataDeletionFormProps> = ({
  setDeleteSubmitted,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IDataDeletionForm>();
  const panelistId = useAppSelector((state) => state.auth.panelistId);
  const accessCode = useAppSelector((state) => state.auth.accessCode);
  const panelist = useAppSelector((state) => state.auth.panelist);

  const [reasons, setReasons] = useState([]);

  const getReasons = async () => {
    try {
      const data = await getDataDeletionReasonList();
      setReasons(data);
    } catch (e) {
      // TODO: handle error
      console.log(e);
    }
  };
  useEffect(() => {
    getReasons();
  }, []);

  const onSubmit: SubmitHandler<IDataDeletionForm> = async ({
    reason,
    email,
  }) => {
    try {
      const response = await deleteDataRequest(
        accessCode,
        reason,
        panelist || email,
        panelistId,
      );
      // TODO: add failure alerts
      if (response.success) {
        setDeleteSubmitted(true);
      } else {
        console.error(response.error);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <section className="mb-10">
        <h1 className="text-2xl mb-5">The Focus Project</h1>
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="text-lg mb-3">Data Deletion Request</h3>
        <Controller
          name="reason"
          control={control}
          rules={{ required: "Please select a reason" }}
          render={({ field }) => (
            <Select
              label="Select an reason"
              className="max-w-sm mb-5"
              variant="bordered"
              {...field}
              errorMessage={errors?.reason?.message}
              isInvalid={!!errors?.reason?.message}
              autoFocus
              classNames={{
                trigger: [
                  "data-[focus=true]:border-primary",
                  "data-[open=true]:border-primary",
                ],
              }}
            >
              {reasons.map((reason) => (
                <SelectItem aria-label={reason.description} key={reason.code}>
                  {reason.description}
                </SelectItem>
              ))}
            </Select>
          )}
        />
        {!panelistId && (
          <Input
            label="Email Address"
            type="text"
            autoComplete="email"
            register={register("email", {
              required: "Please enter your email address",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Please enter a valid email address",
              },
            })}
            errorMessage={errors?.email?.message}
          />
        )}
        <div className="flex items-center justify-content gap-9">
          <Button color="secondary" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default DataDeletionForm;
