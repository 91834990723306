import React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  getKeyValue,
} from "@nextui-org/react";

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

export interface ISessionStatus {
  position: number;
  validation: number;
  lighting: number;
}

interface ScoresTableProps {
  status: ISessionStatus;
  maxAcc: number;
  showPositioningScore: boolean;
  showValidationScore: boolean;
}

interface ScoresTableColumn {
  key: string;
  label: string;
}

interface ScoresTableRow {
  key: string;
  type: string;
  target: string;
  score: string;
  status: JSX.Element;
  hidden?: boolean;
}

const ScoresTable: React.FC<ScoresTableProps> = ({
  status,
  maxAcc,
  showPositioningScore,
  showValidationScore,
}) => {
  const getStatusIcon = (validation: boolean) => {
    if (validation)
      return (
        <CheckCircleIcon className="h-8 p-1 flex text-[#4BB543] focus:outline-none" />
      );
    return (
      <ExclamationCircleIcon className="h-8 p-1 flex text-[#eed202] focus:outline-none" />
    );
  };

  const columns: ScoresTableColumn[] = [
    {
      key: "type",
      label: "Type",
    },
    {
      key: "target",
      label: "Target",
    },
    {
      key: "score",
      label: "Your Score",
    },
    {
      key: "status",
      label: "Status",
    },
  ];

  const rows: ScoresTableRow[] = [
    {
      key: "1",
      type: "Lightning",
      target: "80 or above",
      score: `${Math.floor(status.lighting)} / 100`,
      status: getStatusIcon(status.lighting > 80),
    },
    {
      key: "2",
      type: "Positioning",
      target: "80 or above",
      score: `${status.position} / 100`,
      status: getStatusIcon(status.position >= 80),
      hidden: !showPositioningScore,
    },
    {
      key: "3",
      type: "Validation",
      target: `Under ${maxAcc}`,
      score: Math.floor(status.validation).toString(),
      status: getStatusIcon(status.validation <= maxAcc),
      hidden: !showValidationScore,
    },
  ];

  return (
    <Table
      aria-label="Example table with dynamic content"
      radius="md"
      isCompact
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={rows}>
        {(item) => (
          <TableRow key={item.key} className={item.hidden && "hidden"}>
            {(columnKey) => (
              <TableCell>{getKeyValue(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ScoresTable;
