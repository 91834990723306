import React, { ReactNode, useEffect, useState } from "react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

interface AlertIconProps {
  type: AlertType;
}

export type AlertType = "success" | "error";

export interface IAlert {
  message: string;
  type: AlertType;
  timeout: number;
}

export interface AlertProps extends IAlert {
  handleClose: () => void;
}

export const AlertIcon: React.FC<AlertIconProps> = ({ type }) => {
  switch (type) {
    case "error":
      return <ExclamationTriangleIcon />;
    case "success":
    default:
      return <CheckCircleIcon />;
  }
};

export const Alert: React.FC<AlertProps> = ({
  type,
  message,
  timeout,
  handleClose,
}) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(true);
    });
  }, []);

  useEffect(() => {
    if (!timeout) return () => null;

    const timer = setTimeout(() => {
      setShowAlert(false);
      setTimeout(() => {
        handleClose();
      }, 500);
    }, timeout * 1000);

    return () => clearTimeout(timer);
  }, [timeout, handleClose]);

  const getStatusColour = () => {
    switch (type) {
      case "error":
        return "bg-red-100 text-red-900";
      case "success":
      default:
        return "bg-green-100 text-green-900";
    }
  };

  return (
    <div
      className={`transition ease-in-out duration-500 transform ${showAlert ? "translate-x-0" : "-translate-x-[500px]"}`}
    >
      <div
        className={`rounded-md text-base z-50 rounded shadow-lg inline-flex ${getStatusColour()}`}
      >
        <div className="p-2 inline-flex">
          <div className="h-6 w-6 mr-2 self-center">
            <AlertIcon {...{ type }} />
          </div>
          <p className="flex-start w-96 self-center line-clamp-2">{message}</p>
          <XMarkIcon
            className="h-4 w-4 ml-2 cursor-pointer transform hover:scale-110"
            aria-hidden="true"
            data-testid={message}
            onClick={() => {
              setShowAlert(false);
              setTimeout(() => {
                handleClose();
              }, 500);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const AlertsWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <ul className="flex flex-col-reverse absolute left-8 bottom-4 z-50">
      {children}
    </ul>
  );
};
