/* eslint-disable react/no-unescaped-entities */
import React, { Dispatch, SetStateAction } from "react";

import { Button } from "@nextui-org/react";

interface EmailConfirmationProps {
  setConfirmationSent: Dispatch<SetStateAction<boolean>>;
}

const EmailConfirmation: React.FC<EmailConfirmationProps> = ({
  setConfirmationSent,
}) => {
  return (
    <>
      <section className="mb-10">
        <p className="text-2xl mb-5">The Focus Project</p>
      </section>
      <section>
        <p className="mb-4">
          An email has been sent to you to verify your email address and set up
          your password.
        </p>
        <p className="mb-4">
          Not arrived yet? Don't forget ot check you spam folder.
        </p>
        <Button color="secondary" onClick={() => setConfirmationSent(false)}>
          Re-enter email
        </Button>
      </section>
    </>
  );
};

export default EmailConfirmation;
