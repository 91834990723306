import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";

import { SessionCalibrationContext, SessionRoute } from "@/types/session";
import { setCalibrationTime } from "@/store/reducers/session";
import ErrorFormatter, { ErrorClass } from "@/utils/errorFormat";
import { useAlertContext } from "@/contexts/alertContext";

const SessionCalibration = () => {
  const { broker, dispatch, calibrationRef, trackerRef, errorHandle } =
    useOutletContext<SessionCalibrationContext>();
  const { addAlert } = useAlertContext();
  const navigate = useNavigate();

  const cont = () => {
    navigate(SessionRoute.VALIDATION);
  };

  const calibrate = async () => {
    try {
      const div = calibrationRef.current;
      const trackerDiv = trackerRef.current;
      if (!div || !trackerDiv) {
        const message = ErrorFormatter.getError(
          ErrorClass.CALIBRATION_DIV,
        ).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }

      dispatch(setCalibrationTime());
      await broker.calibrate(div, 50000);
      broker.trackersController.setVideoDiv(trackerDiv);
      broker.trackersController._videoView.render();
      cont();
    } catch (e) {
      const div = calibrationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      errorHandle(e);
    }
  };
  return (
    <>
      <p className="font-semibold mb-10">Step 3: Calibration</p>
      <p className="mb-10">
        On the next page follow the blue dot with your eyes.
      </p>
      <p className="mb-10">Try to keep your head still.</p>
      <Button color="secondary" type="submit" onClick={calibrate}>
        Continue
      </Button>
    </>
  );
};

export default SessionCalibration;
