import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";

import { useAppSelector } from "@/hooks/store";
import {
  SessionValidationContext,
  SessionRoute,
  ErrorDetail,
} from "@/types/session";
import { submitWildValidationRequest } from "@/api/index";
import { ValidationResult } from "@/types/rni";
import ErrorFormatter, { ErrorClass } from "@/utils/errorFormat";
import { useAlertContext } from "@/contexts/alertContext";

const SessionValidation = () => {
  const {
    broker: { trackersController, turnOffCamera, turnOnCamera, validate },
    validationSessionDetails,
    validationRef,
    trackerRef,
    errorHandle,
    setStatus,
  } = useOutletContext<SessionValidationContext>();
  const { addAlert } = useAlertContext();
  const navigate = useNavigate();
  const maxAcc = useAppSelector((state) => state.auth.maxAcc);

  const startValidation = async () => {
    // TODO: SCORE VALIDATION
    try {
      const div = validationRef.current;
      const trackerDiv = trackerRef.current;
      if (!div || !trackerDiv) {
        const message = ErrorFormatter.getError(
          ErrorClass.VALIDATION_DIV,
        ).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }

      trackersController._videoView.releaseDivElement();
      const validation: ValidationResult = await validate(div, 50000);

      setStatus((value) => ({
        ...value,
        validation: validation.validatedErrorCorrection._accuracy,
      }));

      if (validation.validatedErrorCorrection._accuracy > maxAcc) {
        const message = ErrorFormatter.getError(
          ErrorClass.VALIDATION_FAIL,
        ).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }

      await submitWildValidationRequest(
        validation,
        validationSessionDetails.panelist,
        validationSessionDetails.panelistId,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.calibrationTime,
      );
      trackersController.setVideoDiv(trackerDiv);
      trackersController._videoView.render();
      navigate(SessionRoute.EXTERNAL);
    } catch (e) {
      const div = validationRef.current;
      if (div) {
        div.style.display = "none";
      }

      turnOffCamera();
      if (e === ErrorClass.VALIDATION_FAIL) {
        const detail: ErrorDetail = {
          fmt: ErrorFormatter.getError(e),
          action: () => {
            trackersController._detectorCalibrator.reset();
            trackersController._gazeValidator.reset();
            turnOnCamera();
            navigate(SessionRoute.CALIBRATION);
          },
        };
        errorHandle(detail);
      } else {
        errorHandle(e);
      }
    }
  };

  return (
    <>
      <p className="font-semibold mb-10">Step 4: Validation</p>
      <p className="mb-10">
        On the next page follow the green dot with your eyes.
      </p>
      <p className="mb-10">Try to keep your head still.</p>
      <Button color="secondary" onClick={startValidation}>
        Continue
      </Button>
    </>
  );
};

export default SessionValidation;
