import React, { useEffect, useState } from "react";
import { Button, Textarea } from "@nextui-org/react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { useAppSelector } from "@/hooks/store";
import { contactRequest } from "@/api/index";

interface IContactForm {
  message: string;
  reason: string;
}

const Contact = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<IContactForm>();
  const panelistId = useAppSelector((state) => state.auth.panelistId);
  const accessCode = useAppSelector((state) => state.auth.accessCode);

  const [success, setSuccess] = useState("");

  const sendContact: SubmitHandler<IContactForm> = async ({ message }) => {
    try {
      const res = await contactRequest(
        accessCode || "",
        panelistId || 0,
        message,
      );
      setSuccess("Message received. We will be in touch shortly.");
    } catch (e) {
      // TODO add error handling.
      console.error(e);
    }
  };

  useEffect(() => {
    setFocus("message");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(sendContact)}>
      <h1 className="text-2xl mb-5">The Focus Project</h1>
      <p className="mb-5">To contact us please fill in the form below.</p>
      {/* TODO: Add reason dropdown */}
      <Controller
        render={({ field }) => (
          <Textarea
            {...field}
            className="mb-5"
            minLength={5}
            label="Message"
            isInvalid={!!errors?.message?.message}
            errorMessage={errors?.message?.message}
          />
        )}
        control={control}
        name="message"
        rules={{
          required: { value: true, message: "required" },
          minLength: { value: 5, message: "too short" },
        }}
      />
      <p className="mb-5">{success}</p>
      <div className="flex items-center justify-content gap-9">
        <Button color="secondary" type="submit">
          Contact
        </Button>
      </div>
    </form>
  );
};

export default Contact;
