import React, { ReactNode } from "react";

const BulletList: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <ul className="list-disc pl-4 space-y-2">{children}</ul>;
};

const OutlinedBulletList: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <ul className="list-none pl-4 mt-2 space-y-2">{children}</ul>;
};

const OutlinedBulletListItem: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <li className="flex items-start">
      <p className="w-[6px] h-[6px] border-1 border-default-900 rounded-full mr-2 mt-[9px]" />
      {children}
    </li>
  );
};

export { BulletList, OutlinedBulletList, OutlinedBulletListItem };
