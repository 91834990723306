import { createSlice } from "@reduxjs/toolkit";
import { DetailSuccess, LoginSuccess } from "../../api";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loggedIn: false,
    panelist: "",
    panelistId: 0,
    accessCode: "",
    maxAcc: 300,
    maxPrec: 300,
    brokerVersionConfig: {},
    viewabilityVersionConfig: {},
  },
  reducers: {
    logIn: (
      state,
      action: {
        payload: LoginSuccess;
        type: string;
      },
    ) => {
      state.loggedIn = true;
      state.panelist = action.payload.panelist;
      state.panelistId = action.payload.panelistId;
      state.accessCode = action.payload.accessCode;
    },
    updateDetails: (
      state,
      action: {
        payload: DetailSuccess;
        type: string;
      },
    ) => {
      state.maxAcc = action.payload.maxAcc;
      state.maxPrec = action.payload.maxPrec;
      state.brokerVersionConfig = action.payload.brokerVersionConfig;
      state.viewabilityVersionConfig = action.payload.viewabilityVersionConfig;
    },
    logOut: (_state) => {
      return {
        loggedIn: false,
        panelist: "",
        panelistId: 0,
        accessCode: "",
        maxAcc: 300,
        maxPrec: 300,
        brokerVersionConfig: {},
        viewabilityVersionConfig: {},
      };
    },
  },
});

export const { logIn, logOut, updateDetails } = authSlice.actions;

export default authSlice.reducer;
