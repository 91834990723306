import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Input from "@/components/input/input";
import { Button } from "@nextui-org/react";

const ResetPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<{ email: string }>();

  const sendResetPasswordLink: SubmitHandler<{ email: string }> = ({
    email,
  }) => {
    // TODO: send reset password link
    // Clear form and show message that password link has been sent
    try {
      console.log(email);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(sendResetPasswordLink)}>
      <h2 className="text-2xl mb-5">The Focus Project</h2>
      <section className="mb-8">
        <h3 className="block text-gray-700 font-bold mb-2 text-sm">
          Password Reset
        </h3>
        <p>
          Enter your email to receive a password reset link to that address in a
          few minutes. Remember to check the spam folder.
        </p>
      </section>
      <Input
        label="Email Address"
        type="text"
        autoComplete="email"
        register={register("email", {
          required: "Please enter your email address",
          pattern: {
            value: /^\S+@\S+$/i,
            message: "Please enter a valid email address",
          },
        })}
        errorMessage={errors?.email?.message}
      />
      <div className="flex items-center justify-content gap-9">
        <Button type="submit" color="secondary">
          Reset Password
        </Button>
        <Button
          type="button"
          variant="bordered"
          onClick={() => {
            setTimeout(() => {
              navigate("/");
            }, 200);
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default ResetPassword;
